<template>
    <div :class="$vuetify.full?'pa-0 ma-0 my-border task-info-full':'pa-0 ma-0 my-border task-info'" id="task-info">
        <div class="pa-0 ma-0 white" style="height:100%;">
            <v-toolbar height="55" class="gray px-3 ma-0 elevation-0">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="redirectTask(localtask.id)">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on='on' v-bind="attrs" class=" font-weight-light pa-0 ma-0" >mdi-location-enter</v-icon>
                </template>
                <span>{{$t("Task Details")}}</span>  
              </v-tooltip>
            </v-btn>
            <v-btn @click="closeDetails()" icon>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on='on' v-bind="attrs" class=" font-weight-light pa-0 ma-0">mdi-close</v-icon>
                </template>
                 <span>{{$t("close")}}</span>  
              </v-tooltip>
            </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
              <div class="pa-0 ma-0" style="height:calc(100% - 110px); overflow-y:auto">
                <v-row class="pa-0 ma-0" style="min-height:100%;">
                  <v-col cols="12" class="text-center">
                    <v-form v-on:submit.prevent ref="form" v-model="valid" lazy-validation>
                      <div>
                        <div v-if="!this.$checkPermission('Tasks.Approve')"  class="ma-auto text-center">
                          <v-icon v-if="localtask.type==0" class="custom-color-accent-text">mdi-alpha-b-circle</v-icon>
                          <v-icon v-if="localtask.type==1" class="custom-color-accent-text">mdi-alarm</v-icon>
                          <v-icon v-if="localtask.type==3" class="custom-color-accent-text">mdi-check-circle</v-icon>
                          <v-icon v-if="localtask.type==2" class="custom-color-accent-text">mdi-alert-circle-check</v-icon>
                          <v-icon v-if="localtask.type==4" class="custom-color-accent-text">mdi-close-circle</v-icon>
                          {{getLabelLookup('TaskType',localtask.type)[0]}}
                        </div>
                          <v-text-field :label="$t('title')" :placeholder="$t('title')" :rules="[rules.required, rules.min, rules.max]" 
                              outlined dense v-model="localtask.title"></v-text-field>
                          <v-autocomplete
                              :label="$t('labels')"
                              :placeholder="$t('labels')"
                              small-chips
                              multiple
                              dense
                              outlined
                              :items="getLookup('TaskLabel')"
                              item-text="text"
                              item-value="value"
                              autowidth
                              v-model="localtask.label"
                          >
                              <template v-slot:selection="data">
                                <v-chip :input-value="data.selected" small :color="setcolor(data.item.value)">
                                    <strong>{{ getLabelLookup('TaskLabel', data.item.value)[0] }}</strong>
                                </v-chip>
                              </template>
                          </v-autocomplete>
                          <v-menu
                              class="ma-0"
                              v-model="due"
                              :close-on-content-click="false"
                              :nudge-left="50"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="localtask.due_date"
                                  :label="$t('Due Date')"
                                  :placeholder="$t('Due Date')"
                                  prepend-inner-icon="mdi-calendar"
                                  dense
                                  readonly
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="localtask.due_date" :min="nowDate" @input="due = false"></v-date-picker>
                          </v-menu>
                        <v-menu
                              class="ma-0"
                              v-model="dateCompleted"
                              :close-on-content-click="false"
                              :nudge-left="50"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="localtask.date_completed"
                                  :label="$t('Date Closed')"
                                  :placeholder="$t('Date Closed')"
                                  prepend-inner-icon="mdi-calendar"
                                  dense
                                  readonly
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="localtask.date_completed" :max="nowDate" @input="dateCompleted = false"></v-date-picker>
                          </v-menu>
                          <v-autocomplete
                              :items="requests"
                              :label="$t('requestid')"
                              :placeholder="$t('requestid')"
                              item-value="id"
                              outlined
                              item-text="reqCode" 
                              dense
                              clearable
                              :rules="[rules.required]"
                              v-model="localtask.request_id"
                          ></v-autocomplete>
                          <v-autocomplete
                              :items="users.map(user=>{return {value:user.id,text:user.first_name+' '+user.last_name}})"
                              :label="$t('assignee')"
                              :placeholder="$t('assignee')"
                              outlined
                              dense
                              v-model="localtask.assigned_to"
                              clearable
                          >
                          </v-autocomplete>
                          <v-autocomplete
                            v-if="this.$checkPermission('Tasks.Approve')"
                              :items="getLookup('TaskType')"
                              :label="$t('type')"
                              :placeholder="$t('type')"
                              outlined
                              :rules="[rules.required]"
                              dense
                              v-model="localtask.type">
                          </v-autocomplete>
                          <v-textarea
                              counter
                              auto-grow
                              row-height="20"
                              :label="$t('description')"
                              :placeholder="$t('description')"
                              outlined
                              v-model="localtask.description"
                          ></v-textarea>
                      
                      </div>
                    </v-form>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <div
              height="55"
              class="justify-center  ma-0 pa-0" 
              >
                <div class="gray ma-0 pa-0 py-2 text-center" style="width:100%;">
                  <v-btn
                  class="custom-color-accent"
                      depressed
                      :loading="loading4"
                      :disabled="loading4"
                      @click="loader = 'loading4',changeTask()"
                  >
                  <span> {{$t("Apply")}}</span>
                      <template v-slot:loader>
                      <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                      </span>
                      </template>
                  </v-btn>
                </div>
              </div>
        </div>
    </div>
</template>
<script>
import {  mapState,mapActions } from "vuex";
import moment from 'moment';
export default {
    props: ["task","details"],
    data() {
        return {
        nowDate: new moment().toISOString(),
        loader: null,
        loading4: false,
        title:this.$t('Edit'),
        due: false,
        dateCompleted:false,
        valid: true,
        SaveBtn: false,
        localtask:{},
        rules: {
              required: value => !!value || this.$t('required'),
              min: value => (value && value.length >= 3) || this.$t('mincharacters3'),
              max: value => (value && value.length <= 200) || this.$t('maxcharacters200'),
          },
          autoTask:{}
      }
    },
    watch: {
        loader() {
        const l = this.loader;
        this[l] = !this[l];
        this.loader = null;
        },
        details(val){
            if(val){
            this.showEditSidebar()
            }
        },
        task:{ handler(val){
          this.localtask={}
          Object.assign(this.localtask,val)
          }, deep: true
        },
        localtask:{ handler(val){
          if(val.label&&!Array.isArray(val.label))
          this.localtask.label = JSON.parse( val.label )
          }, deep: true
        },
    },
    async created(){
      Object.assign(this.localtask,this.task)
      await this.retriveRequests();
      await this.retriveUsers(); 
      if(this.localRequest){
        this.task.request_id=this.localRequest.id;
      }
    },
    computed: {
    ...mapState("request", {
      requests: "requests",localRequest: "requestItem"
    }),
    ...mapState({
      users: "users"
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
    ...mapState(['user']),
  },
    methods: {
      ...mapActions([ "retriveUsers"]),   
      ...mapActions('request', ['retriveRequests']),
        showEditSidebar() {
            this.title=this.$t('Edit')
            this.$root.$emit("setTaskSidebar", true);
            let element = document.getElementById("task-info");
            element.setAttribute( 'style', 'width:250px !important;' );
            if (window.innerWidth < 600) {
              element.setAttribute( 'style', 'width:100% !important;' );
            }
        },
        closeDetails() {
           let element = document.getElementById("task-info");
          element.setAttribute( 'style', 'width:0px !important;' );
            this.$emit('setDetails',false)
            this.$root.$emit("setTaskSidebar", false); 
        },
        redirectTask(item){
            this.$router.push({ name: "tasks", params: {id: item} });
        },
        getUser(id){
          return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0];
        },
        changeTask() {
        if(this.localtask.assigned_to==undefined)
         this.localtask.assigned_to=null;
        if(this.localtask.request_id==undefined)
         this.localtask.request_id=null;
            (this.$refs.form.validate())? this.updateTask(this.localtask).then(() => {
               let log_en='';
               let log_fr='';
              if(this.localtask.assigned_to!=this.task.assigned_to && this.localtask.assigned_to){
                let fulluser = this.getUser(this.localtask.assigned_to)
                log_en="Has Assigned This Task To: "+fulluser;
                log_fr="A assigné cette tâche à : "+fulluser;
                this.postTaskLog({ task_id: this.localtask.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
              }
              else if(this.localtask.assigned_to!=this.task.assigned_to && this.localtask.assigned_to==null){
                let fulluser = this.getUser(this.task.assigned_to)
                log_en="Has Unassigned "+fulluser;
                log_fr="A désassigné "+fulluser;
                this.postTaskLog({ task_id: this.localtask.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
              }
                //this.$emit('setEditedItem',{})
                this.closeDetails();
                this.loading4 = false;
                //alert emit example
                let item = {text:this.$t("taskUpdateAlert"), type:"dark", alert:true};
                this.$root.$emit("callAlert", item);
            }) :this.loading4=false;this.loader=false
        },
        setcolor(status) {
            if (status === "done") {
                return "success";
            } else if (status === "onHold") {
              return "warning";
            } else if (status === "ongoing") {
              return "primary";
            } else if (status === "todo") {
              return "gray";
            } else if (status === "high") {
              return "error";
            } else if (status === "medium") {
              return "warning";
            } else if (status === "low") {
              return "gray";
            } else{
              return "gray"
            }
        },
        getLabelLookup(column = 'TaskLabel', label){
          return this.configurations
            .filter(filter => filter.LookUpName === column && filter.LookUpKey == label)
            .map(item => {
              
                return localStorage.getItem("language") === "en"
                  ? item.LookUpStringE
                  : item.LookUpStringF;
              
            });
        },
        getLookup(column) {
            return this.configurations
                .filter(filter => filter.LookUpName === column)
                .map(item => {
                let arr = [];
                arr["text"] =
                    localStorage.getItem("language") === "en"
                    ? item.LookUpStringE
                    : item.LookUpStringF;
                arr["value"] = item.LookUpKey;
                return arr;
                });
        },
        ...mapActions("task", ["updateTask","postTaskLog"]),
    },
}
</script>
<style scoped>
.task-info {
  position: fixed;
  height: calc(100% - 53px);
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 0;
}
.task-info-full{
  position: fixed;
  height:100%;
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 0;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
 
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>