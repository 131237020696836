<template>
    <div class="pa-auto px-4 white">
        <div class="px-2 ma-0 pt-8 font-weight-medium text--secondary" style="font-size:1.4em">{{$t("personal")}}</div>
        <div class="px-2 ma-0 font-weight-medium text--disabled">{{$t("generalemployeesinformation")}}</div>
        <div class="hr-sect">{{$t("generalsettings")}}</div>
        <v-row class="pa-0 ma-0 text--secondary">
            <v-col cols="12" sm="10" md="6" lg="4" class="ma-auto pa-auto">
                <v-autocomplete
                    v-model="localUser.lang_pref"
                    :items="languages"
                    :placeholder="$t('language')"
                    :label="$t('language')"
                    item-value="id"
                    item-text="value"
                    dense
                    outlined
                    prepend-icon="mdi-web"
                    hide-details
                ></v-autocomplete>
            </v-col> 
        </v-row>
        <v-row class="pa-0 ma-0 text--secondary">
            <v-col cols="12" sm="10" md="6" lg="4" class="ma-auto pa-auto">
                <v-autocomplete
                    v-model="localUser.two_factor_auth"
                    :items="Two_Factor_Authentications"
                    :placeholder="$t('two_factor_auth')"
                    :label="$t('two_factor_auth')"
                    item-value="id"
                    item-text="value"
                    dense
                    outlined
                    prepend-icon="mdi-lock"
                    hide-details
                ></v-autocomplete>
            </v-col> 
        </v-row>
        <v-row class="pa-0 ma-0 text--secondary">
            <v-col cols="12" sm="10" md="6" lg="4" class="ma-auto pa-auto">
                <v-autocomplete
                    v-model="tasksRows"
                    :items="rows"
                    :placeholder="$t('taskrows')"
                    :label="$t('taskrows')"
                    dense
                    outlined
                    prepend-icon="mdi-format-list-numbered"
                    hide-details
                ></v-autocomplete>
            </v-col> 
        </v-row>
        <v-row class="pa-0 ma-0 text--secondary">
            <v-col cols="12" sm="10" md="6" lg="4" class="ma-auto pa-auto">
                <v-autocomplete
                    v-model="requestsRows"
                    :items="rows"
                    :placeholder="$t('requestrows')"
                    :label="$t('requestrows')"
                    dense
                    outlined
                    prepend-icon="mdi-format-list-numbered"
                    hide-details
                ></v-autocomplete>
            </v-col> 
        </v-row>
        <v-row class="pa-0 ma-0 text--secondary">
            <v-col cols="12" sm="10" md="6" lg="4" class="ma-auto pa-auto">
                <v-autocomplete
                    v-model="dashboardFilter"
                    :items="dashboardFilters"
                    :placeholder="$t('filter')"
                    :label="$t('filter')"
                    dense
                    outlined
                    prepend-icon="mdi-filter"
                    hide-details
                    return-object
                ></v-autocomplete>
            </v-col> 
        </v-row>
        <div class="hr-sect">{{$t("personaldetails")}}</div>
        <v-form  v-on:submit.prevent class="pa-2 pa-sm-12 ma-auto" ref="form"
            v-model="valid"
            lazy-validation >
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.username"
                        dense
                        outlined
                        :rules="[rules.required, rules.minU]"
                        :placeholder="$t('username')"
                        :label="$t('username')"
                        prepend-icon="mdi-account"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.first_name"
                        dense
                        outlined
                        :rules="[rules.required, rules.minU]"
                        :placeholder="$t('firstname')"
                        :label="$t('firstname')"
                        prepend-icon="mdi-card-account-details"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.last_name"
                        dense
                        outlined
                        :rules="[rules.required, rules.minU]"
                        :placeholder="$t('lastname')"
                        :label="$t('lastname')"
                        prepend-icon="mdi-card-account-details-outline"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.email"
                        dense
                        :rules="[rules.required, rules.min, rules.email]"
                        outlined
                        :placeholder="$t('email')"
                        :label="$t('email')"
                        prepend-icon="mdi-email"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.phone1"
                        dense
                        outlined
                        :placeholder="$t('phone')"
                        :label="$t('phone')"
                        prepend-icon="mdi-phone"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.facsimile"
                        dense
                        outlined
                        :placeholder="$t('facsimile')"
                        :label="$t('facsimile')"
                        prepend-icon="mdi-fax"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-text-field
                        v-model="localUser.address"
                        dense
                        outlined
                        :placeholder="$t('address')"
                        :label="$t('address')"
                        prepend-icon="mdi-map-marker"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row class="pa-0 ma-0 text--secondary">
                <v-col cols="12" sm="10" md="6" lg="4" class="mx-auto my-0 pa-0">
                    <v-btn class="pa-auto ma-auto custom-color-accent font-weight-medium float-right" depressed @click=save()>{{$t("save")}}</v-btn>
                </v-col> 
            </v-row>
        </v-form>
    </div>
</template>

<script>
import {  mapActions} from "vuex";
export default {
  props: ['user'],
  data () {
    return {
        languages:[{id:'en',value:this.$t('english')},{id:'fr',value:this.$t('french')}],
        Two_Factor_Authentications:[{id:0,value:this.$t('off')},{id:1,value:this.$t('on')}],
        rows:['5','10','15'],
        tasksRows:'5',
        requestsRows:'10',
        dashboardFilter:{ value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
        dashboardFilters: [
            { value:'0', text:localStorage.getItem('language') === 'en'?'My stuff':'Mes affaires',icon:'mdi-account'},
            { value:'1', text:localStorage.getItem('language') === 'en'?'My group':'Mon group',icon:'mdi-account-group',disabled:true},
            { value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
        ],
        localUser:{},
        valid:true,
        rules: {
            required: value => !!value || this.$t('required'),
            min: v => v.length >= 8 || this.$t('mincharacters'),
            minU: v => v.length >= 3 || this.$t('mincharacters3'), 
            email: v => (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || this.$t('mailmustbevalid')
        },
    }
  },
  created () {
    Object.assign(this.localUser, this.user);  
  },
  beforeMount() {
    this.setTasksRowsNumber();
    this.setRequestsRowsNumber();
    this.setDashboardFilterValue();
  },
  methods: {
    ...mapActions(['updateUser']),
    setDashboardFilterValue: function() {
        this.$vuetify.dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
        this.dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
    },
    setTasksRowsNumber: function() {
        this.$vuetify.tasksRows = localStorage.getItem("tasksRows");
        this.tasksRows = localStorage.getItem("tasksRows");
    },
    setRequestsRowsNumber: function() {
        this.$vuetify.requestsRows = localStorage.getItem("requestsRows");
        this.requestsRows = localStorage.getItem("requestsRows");
    },
    setDashboardFilter() {
        this.$vuetify.dashboardFilter = this.dashboardFilter;
        localStorage.setItem("dashboardFilter",  JSON.stringify(this.$vuetify.dashboardFilter));
    },
    setTasksRows() {
        this.$vuetify.tasksRows = this.tasksRows;
        localStorage.setItem("tasksRows", this.$vuetify.tasksRows);
    },
    setRequestsRows() {
        this.$vuetify.requestsRows = this.requestsRows;
        localStorage.setItem("requestsRows", this.$vuetify.requestsRows);
    },
    save(){
        (this.$refs.form.validate())?this.updateUser(this.localUser).then(()=>{
                this.setDashboardFilter();
                this.setTasksRows();
                this.setRequestsRows();
                this.$root.$emit("callAlert",  {text:this.$t("Informations Updated"), type:"success", alert:true});
        }):'';
    }
  },
}
</script>

<style scoped lang="scss">
.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(138, 138, 138, 0.384);
    margin: 40px 0px;
}
.hr-sect:before,
.hr-sect:after {
    content: "";
    flex-grow: 1;
    background: rgba(138, 138, 138, 0.384);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
}
</style>