<template>
  <div>
    <div class="d-flex justify-center text-center">
      <v-row class="pa-0 ma-auto ">
        <v-col  
          v-for="item in getTaskLabel()"
          cols="12"
          sm="6"
          md="4"
          lg="1"
          style="min-width:300px;max-height: 100%;"
          :key="item.value"
          class="pa-0 ma-0 pa-2 "
          @drop="setColumn(item.value)"
          @dragover.prevent 
          @dragenter.prevent>
          <div class="my-border pa-0 ma-0" :style="!isCollapsed(item.value)?'height: calc(100vh - 500px)':'height:auto'">
            <div class="pa-0 ma-0" >
              <v-row class="pa-2 white ma-0 d-flex text--seondary font-semibold font-sans tracking-wide text-sm">
                <v-col class="ma-auto pa-0 d-flex text-left"> 
                  <v-btn class="ma-0 pa-0" @click="setCollapse(item.value)" icon><v-icon v-if="!isCollapsed(item.value)" >mdi-chevron-right</v-icon><v-icon v-else >mdi-chevron-down</v-icon></v-btn>
                  <v-chip small :color="setcolor(item.value)" class="my-auto mx-0 pa-auto">
                        {{ item.text }}
                  </v-chip>
                </v-col>
                <v-col class="ma-auto pa-0 text-right">
                    <v-icon class="ma-0 pa-0">
                      mdi-calendar-check
                    </v-icon>{{getTaskByColumn(item.value).length}}
                    <v-icon class=" custom-color-accent mx-1" v-if="$checkPermission('Tasks.Create')" @click="addTask(item.value)">mdi-plus</v-icon>
                </v-col>
              </v-row>
              <v-divider v-show="!isCollapsed(item.value)"></v-divider>
              <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
              <v-scroll-x-transition >
                <draggable v-show="!isCollapsed(item.value)" :list="getTaskByColumn(item.value)" :animation="200" ghost-class="ghost-card" group="tasks" class="pa-0 ma-0 white" style="height: calc(100vh - 555px);overflow-y:auto;">
                  <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                  <div @dragstart="setTask(task)" @click="showDetails(task)" :class="isTaskSelected(task)?'my-border ma-2 pa-2 draggble selected':'my-border ma-2 pa-2 draggble customlightgray'" v-for="(task) in getTaskByColumn(item.value)" :key="task.id">
                    <div class="text-left d-flex">
                      <div class="ma-auto text--seondary font-semibold font-sans tracking-wide text-sm"><span :class="multitask=='true'?'preview-link custom-color-accent-text':''" @mouseover="multitask=='true'?setBoxID(task.id):''">{{ task.title }}</span></div>
                      <v-spacer></v-spacer>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar v-bind="attrs" v-on="on" class="custom-color-accent" size="35">
                            <span class="text-uppercase" v-if="task.full_assigned">
                              {{ task.full_assigned.first_name.charAt(0) }}{{ task.full_assigned.last_name.charAt(0)}}
                            </span>
                          </v-avatar> 
                        </template>
                        <span v-if="task.full_assigned">{{ task.full_assigned.first_name}} {{ task.full_assigned.last_name}}</span>
                      </v-tooltip>
                    </div>
                    <!-- <div class="text-left">
                      <v-chip v-if="JSON.parse(task.label) && JSON.parse(task.label).length>0"
                        class="pa-0 ma-0 mx-1 justify-center status-chip"
                        x-small
                        :color="setcolor(JSON.parse(task.label)[0])"
                      >
                        <span class="pa-0 ma-0 font-weight-black " >{{ getStatusLookup('TaskLabel', task.label)[0] }}</span>
                      </v-chip>
                      <v-chip x-small class="gray pa-2 ma-0" v-if="JSON.parse(task.label) && JSON.parse(task.label).length>1">
                      <span >+{{JSON.parse(task.label).length-1}}</span>
                      </v-chip>
                    </div> -->
                  </div>
                  <!-- </transition-group> -->
                </draggable>
              </v-scroll-x-transition >
            </div>
          </div>
        </v-col>
      </v-row>
      <div v-if="boxID!=''" @mouseleave="resetBoxID" class="preview-box">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0 ma-0 text-right">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn style="margin-bottom :-60px !important;margin-right:10px" icon v-on='on' v-bind="attrs" @click.stop link :href="getBoxLink()" target="_blank">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("openinnew")}}</span>
              </v-tooltip>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0"><iframe :src="getBoxLink()"  class="preview"></iframe></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import {mapActions, mapState} from 'vuex';
import moment from "moment";

export default {
  name: "Task",
  props:['tasks','selected_task'],
  components: {
    draggable,
  },
  data() {
    return {
      boxID:'',
      addTaskDialog:false,
      collapse_items:[],
      editedItem:{},
      label:[],
      get multitask() {
        return localStorage.getItem('multitask') || null;
      },
      rows:[{id:1,tasks:"12",status:"0",text:"Open"},{id:2,tasks:"3",status:"1",text:"Closed"}]
    };
  },
  watch:{
    addTaskDialog(flag){
      if(flag){
        this.setCurrentTask({})
      }
    }
  },
  computed:{
    ...mapState('configuration',{
      'configurations':'configurations'
    }),
    ...mapState("task", {
      currentTask:'task'
    }),
    ...mapState({
      users: "users"
    }),
     ...mapState(['user']),
  },
  methods:{
    isTaskSelected(item){
      return this.selected_task[0]==item;
    },
    setCollapse(item){
      if(!this.collapse_items.includes(item))
        this.collapse_items.push(item);
      else
        this.collapse_items.splice(this.collapse_items.indexOf(item), 1);
    },
    isCollapsed(item){
      return this.collapse_items.includes(item);
    },
    setDetails(details)
    {
      this.$emit('setDetails',details)
    },
    addTask(label) {
      this.editedItem={}
      this.label.push(label);
      this.editedItem.label=JSON.stringify(this.label);
      this.$emit('addTask',{name:"addTask"})
      this.label=[]
    },
    showDetails(task) {
      this.$emit('showDetails',task);
    },
    getTaskLabel(){
     let cols= this.configurations.filter(i=>i.LookUpName === 'TaskLabel').map(j=>{
        let arr = [];
        arr['text'] = this.$i18n.locale === 'en' ? j.LookUpStringE : j.LookUpStringF;
        arr['value'] = j.LookUpKey;
        return  arr;
      }); 
      let opened=[]
      let closed=[]
      cols.unshift(opened)
      cols.push(closed)
      cols[0].text=this.$t("Opened")
      cols[0].value="open"
      cols[cols.length-1].text=this.$t("closed")
      cols[cols.length-1].value="closed"
      return cols;
    },
    getStatusLookup(column = 'TaskLabel', label){
      return this.configurations
        .filter(filter => filter.LookUpName === column && filter.LookUpKey === JSON.parse(label)[0])
        .map(item => {
          
            return localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          
        });
    },
    getTaskByColumn(column){
      let tasks;
      if(column=='open'){
        tasks = this.tasks.filter(j=>{
          return j.label=='[]'?j.status==1:''
          });
      }
      else if(column=='closed'){
        tasks = this.tasks.filter(j=>{
          return j.status==0
          });
      }
      else{
        tasks = this.tasks.filter(j=>{
          return j.label&&j.status==1 ? j.label.includes(column) : '';
        });
      }
      return tasks;
    },
    resetBoxID(){
      this.boxID='';
    },
    setBoxID(id){
      this.boxID=id;
    },
    getBoxLink(){
      let link='/task/'+this.boxID;
      return link;
    },
    setTask( task ){
      this.setCurrentTask(task);
    },
    setColumn( label ){
      var arr =[]
      if(label=='closed'){
        this.currentTask.status=0;
        this.currentTask.date_completed=moment().format("YYYY-MM-DD HH:mm:ss")
        this.$root.$emit("callAlert", {text: this.$t('taskclosed'), type: "dark", alert: true });
        let log_en="Has Closed This Task.";
        let log_fr="A fermé cette tâche.";
        this.postTaskLog({ task_id: this.currentTask.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
      }
      else if(label=='open'){
        if(this.currentTask.status==0){
          this.$root.$emit("callAlert", {text: this.$t('taskreopened'), type: "dark", alert: true });
          let log_en="Has Reopened This Task.";
          let log_fr="A rouvert cette tâche.";
          this.postTaskLog({ task_id: this.currentTask.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
        }
        this.currentTask.status=1;
        this.currentTask.date_completed='';
        this.currentTask.label='[]';
      }
      else{
         if(this.currentTask.status==0){
          this.$root.$emit("callAlert", {text: this.$t('taskreopened'), type: "dark", alert: true });
          let log_en="Has Reopened This Task.";
          let log_fr="A rouvert cette tâche.";
          this.postTaskLog({ task_id: this.currentTask.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
        }
        this.currentTask.status=1;
        this.currentTask.date_completed='';
        arr.push(label)
        arr = JSON.stringify(arr)
        this.currentTask.label = arr;
      }
      
      this.updateTask( this.currentTask );
    },   
    setcolor(status) {
      if (status === "done") {
        return "success";
      } else if (status === "onHold") {
        return "warning";
      } else if (status === "ongoing") {
        return "primary";
      } else if (status === "todo") {
        return "gray";
      } else if (status === "high") {
        return "error";
      } else if (status === "medium") {
        return "warning";
      } else if (status === "low") {
        return "gray";
      }
      else{
        return "gray";
      }
    },
    ...mapActions('task', ['retriveTasks', 'postTask', 'updateTask', 'setCurrentTask','postTaskLog']),
    ...mapActions([ "retriveUsers"])
  }
};
</script>

<style lang="scss" scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.draggble{
  cursor:grab;
}
</style>
