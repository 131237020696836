<template>
    <div class="white">
        <v-row class="pa-0 ma-0 text--secondary">
            <v-col cols="12" sm="10" md="6" lg="4" class="ma-auto pa-auto">
                <v-form  v-on:submit.prevent class="pa-2 pa-sm-12 ma-auto" ref="form"
                    v-model="valid"
                    lazy-validation >
                    <v-text-field
                        v-model="localUser.username"
                        type='text'
                        outlined
                        :label="$t('username')"
                        readonly
                        :rules="[rules.required, rules.minU]"
                        counter
                        prepend-icon="mdi-account"
                        autofocus
                        dense
                        disabled
                        autocomplete="off"
                    ></v-text-field>
                    <v-text-field
                        v-model="passwordOld"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        :placeholder="$t('password')"
                        :label="$t('password')"
                        prepend-icon="mdi-lock"
                        counter
                        dense
                        outlined
                        @click:append="show1 = !show1"
                    ></v-text-field>
                    <v-text-field
                        v-model="passwordNew"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show2 ? 'text' : 'password'"
                        :placeholder="$t('passwordNew')"
                        :label="$t('passwordNew')"
                        prepend-icon="mdi-lock-outline"
                        counter
                        outlined
                        dense
                        autocomplete="off"
                        @click:append="show2 = !show2"
                    ></v-text-field>
                    <v-text-field
                        v-model="passwordConfirmNew"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show3 ? 'text' : 'password'"
                        :placeholder="$t('passwordConfirmNew')"
                        :label="$t('passwordConfirmNew')"
                        prepend-icon="mdi-lock-check-outline"
                        counter
                        dense
                        outlined
                        autocomplete="off"
                        @click:append="show3 = !show3"
                    ></v-text-field>
                    <v-btn class="pa-auto ma-auto custom-color-accent font-weight-medium float-right"  @click=save() depressed>{{$t("changepassword")}}</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {  mapActions} from "vuex";
export default {
  components: {
  },
  props: ['user'],
  data () {
    return {
        valid: true,
        passwordOld:'',
        passwordNew:'',
        passwordConfirmNew:'',
        show1:false,
        show2:false,
        show3:false,
        rules: {
            required: value => !!value || this.$t('required'),
            min: v => v.length >= 8 || this.$t('mincharacters'),
            minU: v => v.length >= 3 || this.$t('mincharacters3'),
        },
        localUser:{}
    }
  },
  mounted () {
  },
  created () {
          Object.assign(this.localUser, this.user);           
  },
  computed: {
  },
  methods: {
    ...mapActions(['updateUser']),
       save(){

           if(this.passwordNew==this.passwordConfirmNew){
               this.localUser.password=this.passwordNew;
                
                    (this.$refs.form.validate())? this.$store.dispatch('retriveToken', {
                    username: this.localUser.username,
                    password: this.passwordOld,
                    }).then(() => {
                     this.updateUser(this.localUser).then(()=>{
                      this.$root.$emit("callAlert",  {text:this.$t("Password Updated"), type:"success", alert:true});
                        });

                   }).catch(() => {
                          this.$root.$emit("callAlert",  {text:this.$t("Wrong Old Password"), type:"warning", alert:true});
                   }):'';
           }
           else{
                     this.$root.$emit("callAlert",  {text:this.$t("Something went wrong"), type:"warning", alert:true}); 
           }
        }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>