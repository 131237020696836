<template>
  <div v-if="user">
    <v-row class="pa-0 ma-0 text-left">
      <v-col cols="12" align="center">           
        <v-avatar class="custom-color-accent ma-auto" size="100">
          <span class="text-uppercase" style="font-size:2.2em">{{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0)}}</span>
        </v-avatar>
        <div class="pa-0 font-weight-normal text-uppercase" style="font-size:1.2em">{{user.last_name}} {{user.first_name}}</div>
        <div class="pa-0 custom-color-accent-text font-weight-normal text-uppercase">{{user.email}}</div>
      </v-col>
      <v-col cols="12" class="text-left pa-0 ma-0 my-border">
        <v-tabs grow show-arrows background-color="white" slider-color="custom-color-accent"
            color="#046e8b" justify="center" v-model="tab">
           <!--  <v-tab key="1"
             href="#profile">
              <v-icon left>
                mdi-account
              </v-icon>
              <span v-if="!isMobile">{{$t("profile")}}</span>
            </v-tab> -->
            <v-tab key="2"
             href="#settings">
              <v-icon left>
                mdi-account
              </v-icon>
              <span v-if="!isMobile">{{$t("profile")}}</span>
            </v-tab>   
            <v-tab key="3"
             href="#password">
              <v-icon left>
                mdi-lock
              </v-icon>
              <span v-if="!isMobile">{{$t("password")}}</span>
            </v-tab>          
            <v-tab key="4"
             href="#tasks">
              <v-icon left>
                mdi-calendar-month
              </v-icon>
              <span v-if="!isMobile">{{$t("tasks")}}</span>
            </v-tab>
          </v-tabs>
            <v-divider></v-divider>
          <v-tabs-items v-model="tab" class="white px-3" style="height:calc(100vh - 345px) !important;overflow-y:auto">
           <!--  <v-tab-item key="1" value="profile">
              <v-card flat>
                <profileinfo :user="user"></profileinfo>
              </v-card>
            </v-tab-item> -->
            <v-tab-item key="2" value="settings">
              <v-card flat>
                <profilesettings :user="user"></profilesettings>
              </v-card>
            </v-tab-item>
            <v-tab-item key="3" value="password">
              <v-card flat>
                <updatepassword :user="user"></updatepassword>
              </v-card>
            </v-tab-item>
            <v-tab-item key="4" value="tasks">
              <v-card class="transparent ma-0 pa-0" flat>
                <kanban class="pa-3 ma-0" @setDetails="setDetails" @showDetails="showDetails" @addTask="openInSame({name:'addTask'})" :tasks="tasksFiltered"  :selected_task="selected_task"></kanban>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-col>
    </v-row>
    <!-- Edit task section -->
    <v-scroll-x-transition >
      <tasksidebar v-show="details" key="trans2" @setDetails="setDetails" :task="editedItem" :details="details" :label="label" :pid="null"></tasksidebar>
    </v-scroll-x-transition>  
    <!-- /Edit task section -->
  </div>
</template>

<script>
import { mapState , mapGetters , mapActions} from "vuex";

import kanban from "@/components/task/TaskKanban";

import updatepassword from "../components/profile/updatePassword.vue";
import profilesettings from "../components/profile/profileSettings.vue";
//import profileinfo from "../components/profile/profileInfo.vue";
import tasksidebar from "@/components/task/TaskSidebar.vue";
    export default {
      components: {
        updatepassword,
        profilesettings,
        //profileinfo,
        kanban,
        tasksidebar,
      },
        data: () => ({
        selected_task: [],
        isMobile: false,  
        taskFilters:{},
        tab:'',
        details:false,
        editedItem:{},
        label:[],
        clicks:0, 
        delay: 700,
        lastTask:{},
        timer: null,
        filters: {
          owner:2,
          title: "",
          status: [],
          requestId: [],
          assignee: [],
          type: [],
          dateRecieved: [],
          dueDate: [],
          closeDate: [],
          label:[]
          
        },
        }),

      beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
      },

      mounted () {
        this.onResize()
        this.removeFilter('Opened')
        window.addEventListener('resize', this.onResize, { passive: true })
      },

      methods: {
         removeFilter(item){
              Object.keys(this.filters).forEach((key)=>{
              if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
              this.filters[key]={text:"",value:""};        
              else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
              this.filters[key]="";           

              else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
              this.filters[key]= this.filters[key].filter(a=>a.text!=item);

            else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
              this.filters[key]= this.filters[key].filter(a=>a!=item);
              });
                this.filterTasks(this.filters);
            
          }, 
          openInSame(r) {
            this.$router.push(r);
          },
          setDetails(details)
          {
            if(!details)
               this.selected_task=[]
            this.details=details
          },
          showDetails(task) {
            this.selected_task=[];
            this.selected_task.push(task);
            this.clicks++;
            if (this.clicks > 1 && this.lastTask==task) {
              clearTimeout(this.timer);  
              this.redirectTask(task.id);
              this.clicks = 0;
              this.lastTask={}
            }
            else{
                this.editedItem = JSON.parse(JSON.stringify(task));
                this.setDetails(true);
                this.lastTask=task;
                this.timer = setTimeout( () => {
                    this.clicks = 0;
                }, this.delay);
            }
          },
          redirectTask(item){
            this.$router.push({ name: "tasks", params: {id: item} });
          },
        onResize () {
          this.isMobile = window.innerWidth < 600
        },
        ...mapActions('task',['retriveTasks','filterTasks','clearFilter']),
      }, 
      async created() {
          await this.retriveTasks();
          this.clearFilter();
          this.taskFilters.owner=0;
          this.filterTasks(this.taskFilters);
          this.tab= this.$route.query.tab;
          Object.assign(this.filters, this.storeFilters);
          delete this.filters.owner;
      },
      watch:{
        '$route.query.tab'() {
               this.tab= this.$route.query.tab;
            }, 
        tab(val){
          this.$route.query.tab=val;
        },
        tasksFiltered: {
          handler(){
            Object.assign(this.taskFilters, this.storeTaskFilters);
          }, deep: true 
        },
        storeFilters: {
        handler(){
            Object.assign(this.filters, this.storeFilters);  
            delete this.filters.owner;
        }, deep: true 
        },
      },
      computed: {
        ...mapState({
          user: "user"
        }),
        ...mapGetters('task', {
          'tasksFiltered':'tasksFiltered'
        }),
        ...mapState('task', {
          'storeTaskFilters':'filter'
        }),
      },
    }
</script>